import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { SynapsoftFront } from './index.tsx';
import { SynapsoftBack } from './index.tsx';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "synapsoftfront"
    }}>{`SynapsoftFront`}</h2>

    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { SynapsoftFront } from 'namecards';
`}</code></pre>
    <Playground __position={0} __code={'<SynapsoftFront\n  className=\"card\"\n  name=\"최종찬\"\n  designation=\"사원\"\n  department=\"웹오피스팀\"\n  mobile=\"010-6351-6406\"\n  email=\"jongchan@synapsoft.co.kr\"\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      SynapsoftFront,
      SynapsoftBack,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <SynapsoftFront className='card' name='최종찬' designation='사원' department='웹오피스팀' mobile='010-6351-6406' email='jongchan@synapsoft.co.kr' mdxType="SynapsoftFront" />
    </Playground>
    <h2 {...{
      "id": "synapsoftback"
    }}>{`SynapsoftBack`}</h2>

    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { SynapsoftBack } from 'namecards';
`}</code></pre>
    <Playground __position={1} __code={'<SynapsoftBack />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      SynapsoftFront,
      SynapsoftBack,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <SynapsoftBack mdxType="SynapsoftBack" />
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      